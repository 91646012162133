import {effect, Injectable, signal, WritableSignal} from '@angular/core';
export type ScreenSize = 'small' | 'medium' | 'large';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  public readonly height: WritableSignal<number> = signal<number>(window.innerHeight);
  public readonly width: WritableSignal<number> = signal(window.innerWidth);
  public readonly screenSize: WritableSignal<ScreenSize> = signal<ScreenSize>('medium');
  public readonly orientation: WritableSignal<ScreenOrientation['type']> = signal(window.screen.orientation.type);

  private initialHeight: number = window.visualViewport?.height ?? window.innerHeight;

  constructor() {
    if(!window.visualViewport){
      console.log('with visualViewport is undefined');
      window.addEventListener('resize', () => {
        setTimeout(() => {
          const currentHeight = window.innerHeight;

          const isKeyboardOpening: boolean = (this.initialHeight - currentHeight) > 100
          if(isKeyboardOpening) return;

          const isKeyboardClosing: boolean = (this.initialHeight - currentHeight) < -100
          if(isKeyboardClosing){
            this.onKeyboardClosed();
            return;
          }

          this.initialHeight = window.innerHeight; // legitimate resize
          this.width.set(window.innerWidth);
          this.height.set(window.innerHeight);
        }, 100)
      });
    }

    if (window.visualViewport) {
      console.log('with visualViewport');
      window.visualViewport.addEventListener('resize', () => {
        setTimeout(() => {
          const currentHeight = window.visualViewport!.height;

          const isKeyboardOpening: boolean = (this.initialHeight - currentHeight) > 100
          if(isKeyboardOpening) return;

          const isKeyboardClosing: boolean = (this.initialHeight - currentHeight) < -100
          if(isKeyboardClosing){
            this.onKeyboardClosed();
            return;
          }

          this.initialHeight = window.visualViewport!.height; // legitimate resize
          this.width.set(window.visualViewport!.width);
          this.height.set(window.visualViewport!.height);
        })
      })
    }

    window.addEventListener('orientationchange', (event) => {
      setTimeout(() => {
        this.width.set(window.innerWidth);
        this.height.set(window.innerHeight);
        this.orientation.set(window.screen.orientation.type);
        this.initialHeight = this.height();
      }, 100)
    })



    effect(() => {
      let height = this.height();
      document.documentElement.style.setProperty('--real-vh', `${height}px`);
    });

    effect(() => {
      let width: number = this.width();
      if (width < 668) {
        this.screenSize.set('small');
      } else if (width < 1024) {
        this.screenSize.set('medium');
      } else {
        this.screenSize.set('large');
      }
    })
  }

  private onKeyboardClosed(){
    setTimeout(() => {
      this.height.set(this.initialHeight);
      document.activeElement instanceof HTMLElement && document.activeElement.blur();
      window.scrollTo({ top: 0, behavior: 'instant' });

      // iOS safety net => damn iOs is soooo bad...
      document.body.style.display = 'none';
      void document.body.offsetHeight; // trigger reflow
      document.body.style.display = '';
    }, 150)
  }
}
